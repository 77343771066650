import React, { useEffect, useState } from 'react';
import { fetchMarketSummary } from '../services/marketDataService';
import DataCard from './DataCard';
import '../styles/summary.css';

const SummaryComponent = () => {
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMarketClosed, setIsMarketClosed] = useState(false);

  useEffect(() => {
    const checkMarketStatus = () => {
      const now = new Date();
      const day = now.getDay();
      const hours = now.getHours();
      const isClosed = (day === 5 && hours >= 22) || 
                       (day === 6) || 
                       (day === 0) || 
                       (day === 1 && hours < 9);
      setIsMarketClosed(isClosed);
    };

    const loadSummary = async () => {
      try {
        setLoading(true);
        checkMarketStatus();
        if (!isMarketClosed) {
          const summaryText = await fetchMarketSummary();
          setSummary(summaryText);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadSummary();
    // Überprüfe den Marktstatus alle 5 Minuten
    const intervalId = setInterval(checkMarketStatus, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  if (loading) {
    return <div className="loading">Loading market summary...</div>;
  }

  if (error) {
    return <div className="error">Error fetching market summary: {error}</div>;
  }

  return (
    <DataCard
      title="Marktpuls"
      category="Marktübersicht"
      isRealtime={!isMarketClosed}
    >
      <div className={`summary-text-container ${isMarketClosed ? 'market-closed' : ''}`}>
        <div className="summary-text">
          {isMarketClosed ? "MÄRKTE GESCHLOSSEN" : summary}
        </div>
      </div>
    </DataCard>
  );
};

export default SummaryComponent;
