import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSubscription } from '../contexts/SubscriptionContext';
import VixComponent from '../components/VixComponent';
import AAIIComponent from '../components/AAIIComponent';
import SummaryComponent from '../components/SummaryComponent';
import MarketScoreComponent from '../components/MarketScoreComponent';
import UnemploymentComponent from '../components/UnemploymentComponent';
import FiscalFlowsComponent from '../components/FiscalFlowsComponent';
import M2MoneyGrowthComponent from '../components/M2MoneyGrowthComponent';
import CreditCardDelinquencyComponent from '../components/CreditCardDelinquencyComponent';
import PersonalSavingRateComponent from '../components/PersonalSavingRateComponent';
import EarningsCallComponent from '../components/EarningsCallComponent';
import RecessionIndicatorComponent from '../components/RecessionIndicatorComponent';
import { useMediaQuery } from 'react-responsive';
import Liquiditatsindikator from '../components/Liquiditatsindikator';
import GlobalPEComponent from '../components/GlobalPEComponent';
import ZinsInjektionComponent from '../components/ZinsInjektionComponent';
import AudioPlayerComponent from '../components/AudioPlayerComponent';
import UpgradeOverlay from '../components/UpgradeOverlay';

const Home = () => {
  const { subscription, loading } = useSubscription();
  const [fiscalFlowsState, setFiscalFlowsState] = useState({ state: 'stable', timestamp: new Date().toISOString() });

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Lade Mitgliedschafts-Status...</p>
      </div>
    );
  }

  return (
    <div className="home-page">
      {!hasActiveSubscription && (
        <UpgradeOverlay />
      )}
      <div className={`components-grid ${!hasActiveSubscription ? 'blurred' : ''}`}>
        <SummaryComponent />
        <AudioPlayerComponent />
        <Liquiditatsindikator />
        <ZinsInjektionComponent />
        <GlobalPEComponent />
        <RecessionIndicatorComponent />
        <EarningsCallComponent />
        <VixComponent />
        <AAIIComponent />
        <UnemploymentComponent />
        <FiscalFlowsComponent setFiscalFlowsState={setFiscalFlowsState} />
        <M2MoneyGrowthComponent />
        <PersonalSavingRateComponent />
        <CreditCardDelinquencyComponent />
        <MarketScoreComponent fiscalFlowsState={fiscalFlowsState.state} />
      </div>
    </div>
  );
};

export default Home;
