import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://jfhelnsdfzbwwrmbuoms.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpmaGVsbnNkZnpid3dybWJ1b21zIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjY2NTc0NTMsImV4cCI6MjA0MjIzMzQ1M30.znNkgn8tTnmSFZYmRYV6IbTKrWYtg9Ql-EDvpPyOTgA';

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Create a singleton instance
let supabaseInstance = null;

const createSupabaseClient = () => {
  if (supabaseInstance) {
    return supabaseInstance;
  }

  supabaseInstance = createClient(supabaseUrl, supabaseAnonKey, {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true,
      storage: {
        getItem: (key) => {
          const value = document.cookie.match(new RegExp(`(^| )${key}=([^;]+)`))?.[2];
          try {
            return value ? JSON.parse(value) : null;
          } catch {
            return value;
          }
        },
        setItem: (key, value) => {
          const stringValue = typeof value === 'object' ? JSON.stringify(value) : value;
          const expiresIn = 30 * 24 * 60 * 60 * 1000; // 30 days
          const expires = new Date(Date.now() + expiresIn).toUTCString();
          document.cookie = `${key}=${stringValue}; expires=${expires}; path=/; SameSite=Strict; Secure`;
        },
        removeItem: (key) => {
          document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Strict; Secure`;
        },
      },
    },
    headers: {
      'apikey': supabaseAnonKey,
      'Authorization': `Bearer ${supabaseAnonKey}`
    }
  });

  return supabaseInstance;
};

export const supabase = createSupabaseClient();

let isConnected = true;
let connectionRetryCount = 0;
const MAX_RETRIES = 3;

// Store the original withRetry implementation
const originalWithRetry = async (operation) => {
  try {
    const result = await operation();
    isConnected = true;
    connectionRetryCount = 0;
    return result;
  } catch (error) {
    console.error('Supabase operation error:', error);
    
    if (error.message?.includes('JWT expired') || error.message?.includes('invalid token')) {
      try {
        const { data: { session }, error: refreshError } = await supabase.auth.getSession();
        if (refreshError) throw refreshError;
        if (!session) {
          await supabase.auth.signOut();
          throw new Error('Session expired. Please login again.');
        }
      } catch (refreshError) {
        console.error('Session refresh failed:', refreshError);
        await supabase.auth.signOut();
        throw new Error('Session refresh failed. Please login again.');
      }
    }

    if (connectionRetryCount < MAX_RETRIES) {
      connectionRetryCount++;
      const delay = Math.min(1000 * Math.pow(2, connectionRetryCount), 10000);
      await new Promise(resolve => setTimeout(resolve, delay));
      return originalWithRetry(operation);
    }

    throw error;
  }
};

supabase.auth.onAuthStateChange(async (event, session) => {
  if (event === 'TOKEN_REFRESHED') {
    isConnected = true;
    connectionRetryCount = 0;
  } else if (event === 'SIGNED_OUT') {
    isConnected = false;
    connectionRetryCount = 0;
  }
});

// Enhanced withRetry with connection check
export const withRetry = async (operation) => {
  await checkConnection();
  return originalWithRetry(operation);
};

export const sendMessage = async ({ name, message, reply_to }) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('User not authenticated');
  
  const isAdmin = user.email === '100x@maximilian.business';
  
  const { data, error } = await supabase
    .from('public_chat')
    .insert([{ 
      name, 
      message, 
      reply_to,
      user_subscription_id: user.id,
      date: new Date().toISOString(),
      visible: isAdmin
    }]);

  if (error) throw error;
  return data;
};

export const deleteMessage = async (messageId) => {
  const { error } = await supabase
    .from('public_chat')
    .delete()
    .eq('id', messageId);

  if (error) throw error;
  return { success: true };
};

export const updateMessage = async (messageId, newContent) => {
  const { error } = await supabase
    .from('public_chat')
    .update({ message: newContent })
    .eq('id', messageId);

  if (error) throw error;
};

export const getUserSubscription = async () => {
  return withCache('user_subscription')(async () => {
    const { data: { user }, error: userError } = await supabase.auth.getUser();
    if (userError) throw userError;
    if (!user) return null;

    try {
      const { data, error } = await supabase
        .from('user_subscriptions')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error && error.code !== 'PGRST116') { // PGRST116 is "not found" error
        console.error('Subscription fetch error:', error);
        throw error;
      }

      return data || null;
    } catch (error) {
      console.error('Error in getUserSubscription:', error);
      throw error;
    }
  });
};

export const updateUserSubscription = async (userId, subscriptionData) => {
  const { data, error } = await supabase
    .from('user_subscriptions')
    .update(subscriptionData)
    .eq('user_id', userId)
    .select();

  if (error) throw error;
  return data;
};

export const uploadImage = async (file) => {
  const fileExt = file.name.split('.').pop();
  const fileName = `${Math.random()}.${fileExt}`;
  const filePath = `chat-images/${fileName}`;

  try {
    const { data, error } = await supabase.storage
      .from('chat-images')
      .upload(filePath, file);

    if (error) {
      console.error('Error uploading file:', error);
      throw error;
    }

    const { data: publicUrl, error: urlError } = supabase.storage
      .from('chat-images')
      .getPublicUrl(filePath);

    if (urlError) {
      console.error('Error getting public URL:', urlError);
      throw urlError;
    }

    return publicUrl.publicUrl;
  } catch (error) {
    console.error('Unexpected error in uploadImage:', error);
    throw error;
  }
};

export const likeMessage = async (messageId, userId) => {
  // First, fetch the current message
  const { data: message, error: fetchError } = await supabase
    .from('public_chat')
    .select('like')
    .eq('id', messageId)
    .single();

  if (fetchError) throw fetchError;

  // Prepare the new like array
  let currentLikes = [];
  try {
    currentLikes = JSON.parse(message.like || '[]');
  } catch (e) {
    // Error handling remains, just remove console.error
  }
  
  if (!Array.isArray(currentLikes)) {
    currentLikes = [];
  }

  const newLikes = currentLikes.includes(userId) 
    ? currentLikes 
    : [...currentLikes, userId];

  // Update the message with the new like array
  const { data, error } = await supabase
    .from('public_chat')
    .update({ like: JSON.stringify(newLikes) })
    .eq('id', messageId)
    .select();

  if (error) throw error;

  if (!data || data.length === 0) {
    throw new Error('No data returned from update operation');
  }

  return data;
};

export const unlikeMessage = async (messageId, userId) => {
  // First, fetch the current message
  const { data: message, error: fetchError } = await supabase
    .from('public_chat')
    .select('like')
    .eq('id', messageId)
    .single();

  if (fetchError) throw fetchError;

  // Prepare the new like array
  let currentLikes = [];
  try {
    currentLikes = JSON.parse(message.like || '[]');
  } catch (e) {
    // Error handling remains, just remove console.error
  }
  
  if (!Array.isArray(currentLikes)) {
    currentLikes = [];
  }

  const newLikes = currentLikes.filter(id => id !== userId);

  // Update the message with the new like array
  const { data, error } = await supabase
    .from('public_chat')
    .update({ like: JSON.stringify(newLikes) })
    .eq('id', messageId)
    .select();

  if (error) throw error;

  if (!data || data.length === 0) {
    throw new Error('No data returned from update operation');
  }

  return data;
};

export const storeLead = async (email, receiveInfo) => {
  return withRetry(async () => {
    const { data, error } = await supabase
      .from('leads')
      .insert({
        email: email,
        receive_info: receiveInfo
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  });
};

export const signIn = async (email) => {
  return withRetry(async () => {
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: `${window.location.origin}/auth/callback`,
      },
    });
    if (error) throw error;
    return { success: true };
  });
};

export const getVideoProgress = async (courseId) => {
  return withCache(`video_progress_${courseId}`)(async () => {
    return withRetry(async () => {
      const { data: { user } } = await supabase.auth.getUser();
      
      const { data, error } = await supabase
        .from('mastermind_progress')
        .select('*')
        .eq('course_id', courseId)
        .eq('user_id', user.id)
        .single();

      if (error && error.code !== 'PGRST116') {
        throw error;
      }
      return data;
    });
  });
};

export const updateVideoProgress = async (courseId, progress, lastPosition) => {
  return withOptimisticUpdate(
    `video_progress_${courseId}`,
    (oldData) => ({ ...oldData, progress, last_position: lastPosition })
  )(async () => {
    const { data: { user } } = await supabase.auth.getUser();
    
    const { data, error } = await supabase
      .from('mastermind_progress')
      .upsert(
        {
          course_id: courseId,
          progress: progress,
          last_position: lastPosition,
          user_id: user.id
        },
        {
          onConflict: 'user_id,course_id',
          ignoreDuplicates: false
        }
      )
      .select();

    if (error) {
      console.error('Error in updateVideoProgress:', error);
      throw error;
    }
    return data;
  });
};

export const incrementAudioPlays = async (audioUrl) => {
  try {
    // First, try to get the current record
    const { data: currentData, error: fetchError } = await supabase
      .from('audio_plays')
      .select('play_count')
      .eq('audio_url', audioUrl)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') { // PGRST116 is "not found" error
      throw fetchError;
    }

    if (!currentData) {
      // No record exists, create a new one
      const { data: insertData, error: insertError } = await supabase
        .from('audio_plays')
        .insert([{ 
          audio_url: audioUrl, 
          play_count: 1 
        }])
        .select()
        .single();

      if (insertError) throw insertError;
      return insertData;
    } else {
      // Record exists, update it
      const newCount = (currentData.play_count || 0) + 1;
      const { data: updateData, error: updateError } = await supabase
        .from('audio_plays')
        .update({ play_count: newCount })
        .eq('audio_url', audioUrl)
        .select()
        .single();

      if (updateError) throw updateError;
      return updateData;
    }
  } catch (error) {
    throw error;
  }
};

export const getAudioPlays = async (audioUrl) => {
  try {
    const { data, error } = await supabase
      .from('audio_plays')
      .select('play_count')
      .eq('audio_url', audioUrl)
      .single();

    if (error) throw error;
    return data?.play_count || 0;
  } catch (error) {
    return 0;
  }
};

export const approveMessage = async (messageId) => {
  const { data, error } = await supabase
    .from('public_chat')
    .update({ visible: true })
    .eq('id', messageId)
    .select();

  if (error) throw error;
  return data;
};

export const addToWishlist = async (symbol, companyName) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('User not authenticated');

  const { data, error } = await supabase
    .from('stock_wishlist')
    .insert([{
      symbol,
      company_name: companyName,
      created_by: user.id
    }])
    .select()
    .single();

  if (error) {
    if (error.code === '23505') { // Unique violation
      throw new Error('Diese Aktie ist bereits in der Wunschliste');
    }
    throw error;
  }
  return data;
};

export const getWishlist = async () => {
  return withCache('wishlist', 30000)(async () => {
    const { data, error } = await supabase
      .from('stock_wishlist')
      .select('*')
      .order('upvote_count', { ascending: false });

    if (error) throw error;
    return data;
  });
};

export const toggleWishlistVote = async (wishlistId) => {
  return withOptimisticUpdate(
    'wishlist',
    (oldData) => {
      const item = oldData.find(i => i.id === wishlistId);
      if (item) {
        const hasVoted = item.hasVoted;
        return oldData.map(i => i.id === wishlistId 
          ? { ...i, upvote_count: i.upvote_count + (hasVoted ? -1 : 1), hasVoted: !hasVoted }
          : i
        );
      }
      return oldData;
    }
  )(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('User not authenticated');

      // Check if vote exists
      const { data: existingVote } = await supabase
        .from('stock_wishlist_votes')
        .select('*')
        .eq('wishlist_id', wishlistId)
        .eq('user_id', user.id)
        .single();

      if (existingVote) {
        // Remove vote and decrement count
        await supabase.rpc('decrement_vote', { wishlist_id: wishlistId });
        await supabase
          .from('stock_wishlist_votes')
          .delete()
          .eq('wishlist_id', wishlistId)
          .eq('user_id', user.id);
        
        // Get updated wishlist item
        const { data: updatedItem } = await supabase
          .from('stock_wishlist')
          .select('*')
          .eq('id', wishlistId)
          .single();
        
        return { data: updatedItem, hasVoted: false };
      } else {
        // Add vote and increment count
        await supabase.rpc('increment_vote', { wishlist_id: wishlistId });
        await supabase
          .from('stock_wishlist_votes')
          .insert({
            wishlist_id: wishlistId,
            user_id: user.id
          });
        
        // Get updated wishlist item
        const { data: updatedItem } = await supabase
          .from('stock_wishlist')
          .select('*')
          .eq('id', wishlistId)
          .single();
        
        return { data: updatedItem, hasVoted: true };
      }
    } catch (error) {
      throw error;
    }
  });
};

export const getUserVotes = async () => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) return [];

  const { data, error } = await supabase
    .from('stock_wishlist_votes')
    .select('wishlist_id')
    .eq('user_id', user.id);

  if (error) throw error;
  return data.map(vote => vote.wishlist_id);
};

// Add request cache
const cache = new Map();
const CACHE_TTL = 5 * 60 * 1000; // 5 minutes

const withCache = (key, ttl = CACHE_TTL) => async (operation) => {
  const cached = cache.get(key);
  if (cached && Date.now() - cached.timestamp < ttl) {
    return cached.data;
  }

  const result = await operation();
  cache.set(key, {
    data: result,
    timestamp: Date.now()
  });
  return result;
};

// Add optimistic update helper
const withOptimisticUpdate = (key, updateFn) => async (operation) => {
  const oldData = cache.get(key)?.data;
  if (oldData) {
    const optimisticData = updateFn(oldData);
    cache.set(key, {
      data: optimisticData,
      timestamp: Date.now()
    });
  }

  try {
    const result = await operation();
    cache.set(key, {
      data: result,
      timestamp: Date.now()
    });
    return result;
  } catch (error) {
    if (oldData) {
      cache.set(key, {
        data: oldData,
        timestamp: Date.now()
      });
    }
    throw error;
  }
};

// Add cache invalidation on auth state change
supabase.auth.onAuthStateChange(async (event) => {
  if (event === 'SIGNED_OUT') {
    cache.clear();
  }
});

// Add batch operation helper for multiple operations
export const batchOperation = async (operations) => {
  return Promise.all(operations.map(op => withRetry(op)()));
};

// Add connection health check
let lastHealthCheck = Date.now();
const HEALTH_CHECK_INTERVAL = 60000; // 1 minute

const checkConnection = async () => {
  if (Date.now() - lastHealthCheck < HEALTH_CHECK_INTERVAL) {
    return;
  }

  try {
    await supabase.from('health_check').select('count').limit(1);
    lastHealthCheck = Date.now();
    isConnected = true;
  } catch (error) {
    console.warn('Connection health check failed:', error);
    isConnected = false;
  }
};
