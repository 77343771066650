import React, { useState, useEffect } from 'react';
import { useSubscription } from '../contexts/SubscriptionContext';
import Spinner from '../components/Spinner';
import UpgradeOverlay from '../components/UpgradeOverlay';
import '../styles/portfolio.css';

const Portfolio = () => {
  const [portfolio, setPortfolio] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const { subscription } = useSubscription();

  useEffect(() => {
    const fetchPortfolio = async () => {
      const mockPortfolio = [
        { 
          name: 'Tesla', 
          symbol: 'TSLA', 
          positionSize: 30.33, 
          currentGain: 1325.98
        },
        { 
          name: 'Meta Platforms', 
          symbol: 'META', 
          positionSize: 11.53, 
          currentGain: 127.44
        },
        { 
          name: 'iShares 20+ Year Treasury Bond ETF', 
          symbol: 'TLT', 
          positionSize: 8.40, 
          currentGain: -7.19
        },
        { 
          name: 'Amazon', 
          symbol: 'AMZN', 
          positionSize: 8.33, 
          currentGain: 528.00
        },
        { 
          name: 'SoFi Technologies', 
          symbol: 'SOFI', 
          positionSize: 5.83, 
          currentGain: 69.94
        },
        { 
          name: 'Wise', 
          symbol: 'WISE', 
          positionSize: 5.14, 
          currentGain: 53.85
        },
        { 
          name: 'Tencent', 
          symbol: 'TCEHY', 
          positionSize: 4.92, 
          currentGain: -12.32
        },
        { 
          name: 'Cloudflare', 
          symbol: 'NET', 
          positionSize: 4.60, 
          currentGain: 73.28
        },
        { 
          name: 'KraneShares CSI China Internet ETF', 
          symbol: 'KWEB', 
          positionSize: 4.34, 
          currentGain: -15.58
        },
        { 
          name: 'Mercado Libre', 
          symbol: 'MELI', 
          positionSize: 4.06, 
          currentGain: 188.27
        },
        { 
          name: 'The Trade Desk', 
          symbol: 'TTD', 
          positionSize: 3.20, 
          currentGain: 108.10
        },
        { 
          name: 'Shopify', 
          symbol: 'SHOP', 
          positionSize: 2.36, 
          currentGain: 246.14
        },
        { 
          name: 'Airbnb', 
          symbol: 'ABNB', 
          positionSize: 1.41, 
          currentGain: 3.73
        },
        { 
          name: 'Root Inc', 
          symbol: 'ROOT', 
          positionSize: 1.25, 
          currentGain: -8.23
        },
        { 
          name: 'Roblox', 
          symbol: 'RBLX', 
          positionSize: 1.20, 
          currentGain: -8.20
        },
        { 
          name: 'Enphase Energy', 
          symbol: 'ENPH', 
          positionSize: 1.13, 
          currentGain: -26.95
        },
        { 
          name: 'Rocket Lab USA', 
          symbol: 'RKLB', 
          positionSize: 0.88, 
          currentGain: 362.67
        },
        { 
          name: 'Palantir Technologies', 
          symbol: 'PLTR', 
          positionSize: 0.53, 
          currentGain: 797.94
        },
        { 
          name: 'Lithium Americas', 
          symbol: 'LAC', 
          positionSize: 0.57, 
          currentGain: -37.76
        },
      ];

      setPortfolio(mockPortfolio);
      setIsLoading(false);
    };

    fetchPortfolio();
  }, []);

  const handleCloseDisclaimer = () => {
    setShowDisclaimer(false);
  };

  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';

  return (
    <div className="portfolio-page">
      <h1>Aktienportfolio</h1>
      <p>Aktualisiert am: {new Date().toLocaleDateString('de-DE')}</p>
      
      <div className="historical-performance data-card">
        <h2>Historische Performance</h2>
        <div className="performance-grid">
          <div className="performance-year">
            <span className="year">2023:</span>
            <span className="return positive">+95,37%</span>
          </div>
          <div className="performance-year">
            <span className="year">2024:</span>
            <span className="return positive">+66,96%</span>
          </div>
          <div className="performance-year">
            <span className="year">2025:</span>
            <span className="return positive">+3,21%</span>
            <span className="date-note">(Seit Jahresbeginn)</span>
          </div>
        </div>
        
        <div className="performance-chart">
          <img 
            src="/images/yearly performance 2023.png" 
            alt="Jährliche Performance Übersicht" 
            className="performance-image"
          />
        </div>
      </div>

      <br />
      {!hasActiveSubscription && <UpgradeOverlay />}
      <div className={`components-grid ${!hasActiveSubscription ? 'blurred' : ''}`}>
        {showDisclaimer && (
          <div className="page-disclaimer-box">
            <button className="page-close-disclaimer" onClick={handleCloseDisclaimer}>×</button>
            <h3>Rechtlicher Hinweis und Haftungsausschluss</h3>
            <div className="page-disclaimer-content">
              <p>
                Die auf dieser Webseite bereitgestellten Informationen, einschließlich aber nicht beschränkt auf Portfoliodaten, Analysen und Kommentare, dienen ausschließlich zu Informations- und Bildungszwecken. Sie stellen unter keinen Umständen eine Anlageberatung, Empfehlung oder Aufforderung zum Kauf oder Verkauf von Wertpapieren oder anderen Finanzinstrumenten dar.
              </p>
              <ul>
                <li>Jegliche Investitionsentscheidungen, die Sie auf Grundlage der hier präsentierten Informationen treffen, erfolgen ausschließlich auf Ihr eigenes Risiko.</li>
                <li>Wir übernehmen keinerlei Haftung für etwaige Verluste oder Schäden, die direkt oder indirekt aus der Nutzung dieser Informationen resultieren können.</li>
                <li>Vergangene Wertentwicklungen, Simulationen oder Prognosen sind kein verlässlicher Indikator für zukünftige Ergebnisse.</li>
                <li>Es besteht die Möglichkeit, dass Sie nicht den ursprünglich investierten Betrag zurückerhalten.</li>
              </ul>
              <p>
                Bevor Sie eine Anlageentscheidung treffen, sollten Sie:
              </p>
              <ol>
                <li>Ihre individuellen finanziellen Umstände prüfen</li>
                <li>Ihre Anlageziele und Risikotoleranz sorgfältig abwägen</li>
                <li>Unabhängigen, professionellen Finanzrat einholen</li>
                <li>Alle relevanten Risiken, Gebühren und Bedingungen vollständig verstehen</li>
              </ol>
              <p>
                Durch die Nutzung dieser Webseite erklären Sie sich mit den hierin enthaltenen Bedingungen einverstanden und bestätigen, dass Sie die damit verbundenen Risiken verstanden haben. Bei Nichteinverständnis bitten wir Sie, diese Webseite nicht zu nutzen.
              </p>
            </div>
          </div>
        )}
        
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="portfolio-list data-card">
            {portfolio.map((stock, index) => (
              <div key={index} className="portfolio-item">
                <div className="portfolio-item-main">
                  <div className="portfolio-item-name">
                    <h2>{stock.name}</h2>
                    <span className="portfolio-item-symbol">{stock.symbol}</span>
                  </div>
                  <div className={`portfolio-item-gain ${stock.currentGain >= 0 ? 'positive' : 'negative'}`}>
                    {stock.currentGain.toFixed(2)}%
                  </div>
                </div>
                <div className="portfolio-item-details">
                  <span>Positionsgröße: {stock.positionSize.toFixed(2)}%</span>
                </div>
                <div className="portfolio-item-info">
                  <p>{stock.info}</p>
                </div>
                {stock.symbol === 'RBLX' && (
                  <div className="stock-warning">
                    <p>
                      <strong>Warnung:</strong> Es gibt einen neuen Shortseller-Bericht über Roblox mit dem Titel "Roblox: Inflated Key Metrics For Wall Street". Bitte informieren Sie sich über die möglichen Risiken. 
                      <a href="https://hindenburgresearch.com/roblox/" target="_blank" rel="noopener noreferrer">Mehr Informationen hier</a>.
                    </p>
                  </div>
                )}
                {stock.symbol === 'TLT' && (
                  <div className="stock-info">
                    <p>
                      <strong>Info:</strong> Der Anleihen-ETF bietet zusätzlich eine jährliche Rendite von ca. 3,9% mit monatlicher Ausschüttung.
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Portfolio;
