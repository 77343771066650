import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { Tooltip } from 'react-tooltip';
import '../styles/earningscalender.css';


const EarningsCalendar = ({ isOpen, setIsOpen }) => {
  const wirtschaftsdaten = [
    { ereignis: 'ISM Produktions-PMI DEZ', datum: new Date(2025, 0, 3, 16, 0), konsens: '48,4', vorherig: '48,4', aktuell: '49,3', erklaerung: 'Misst die Aktivität im verarbeitenden Gewerbe. Wichtiger Frühindikator für Wirtschaftswachstum und kann Industrieaktien beeinflussen.' },
    { ereignis: 'ISM Dienstleistungs-PMI DEZ', datum: new Date(2025, 0, 7, 16, 0), konsens: '53,3', vorherig: '52,1', aktuell: '54,1', erklaerung: 'Misst die Aktivität im Dienstleistungssektor. Wichtiger Indikator für den größten Sektor der US-Wirtschaft.' },
    { ereignis: 'JOLTs Stellenangebote NOV', datum: new Date(2025, 0, 7, 16, 0), konsens: '7,70M', vorherig: '7,839M', aktuell: '8,098M', erklaerung: 'Zeigt die Anzahl der offenen Stellen. Wichtiger Indikator für den Arbeitsmarkt.' },
    { ereignis: 'FOMC Protokoll', datum: new Date(2025, 0, 8, 20, 0), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Detaillierte Aufzeichnung der letzten Fed-Sitzung.' },
    { ereignis: 'Beschäftigung außerhalb der Landwirtschaft DEZ', datum: new Date(2025, 0, 10, 14, 30), konsens: '154K', vorherig: '227K', aktuell: '-', erklaerung: 'Zeigt die Anzahl neuer Arbeitsplätze. Einer der wichtigsten Arbeitsmarktindikatoren.' },
    { ereignis: 'Arbeitslosenquote DEZ', datum: new Date(2025, 0, 10, 14, 30), konsens: '4,2%', vorherig: '4,2%', aktuell: '-', erklaerung: 'Misst den Anteil der Arbeitslosen an der Erwerbsbevölkerung.' },
    { ereignis: 'Michigan Verbrauchervertrauen Vorl. JAN', datum: new Date(2025, 0, 10, 16, 0), konsens: '73,8', vorherig: '74,0', aktuell: '-', erklaerung: 'Misst die Verbraucherstimmung. Wichtiger Indikator für zukünftiges Konsumverhalten.' },
    { ereignis: 'Erzeugerpreisindex MoM DEZ', datum: new Date(2025, 0, 14, 14, 30), konsens: '-', vorherig: '0,4%', aktuell: '-', erklaerung: 'Misst Preisänderungen auf Produzentenebene. Frühindikator für Verbraucherpreisinflation.' },
    { ereignis: 'Kerninflationsrate MoM DEZ', datum: new Date(2025, 0, 15, 14, 30), konsens: '-', vorherig: '0,3%', aktuell: '-', erklaerung: 'Misst Preisänderungen ohne volatile Lebensmittel- und Energiepreise.' },
    { ereignis: 'Kerninflationsrate YoY DEZ', datum: new Date(2025, 0, 15, 14, 30), konsens: '3,0%', vorherig: '3,3%', aktuell: '-', erklaerung: 'Jährliche Veränderung der Kerninflation. Zeigt langfristige Inflationstrends.' },
    { ereignis: 'Inflationsrate MoM DEZ', datum: new Date(2025, 0, 15, 14, 30), konsens: '0,3%', vorherig: '0,3%', aktuell: '-', erklaerung: 'Monatliche Veränderung der Verbraucherpreise.' },
    { ereignis: 'Inflationsrate YoY DEZ', datum: new Date(2025, 0, 15, 14, 30), konsens: '2,3%', vorherig: '2,7%', aktuell: '-', erklaerung: 'Jährliche Inflationsrate. Zentral für geldpolitische Entscheidungen.' },
    { ereignis: 'Einzelhandelsumsätze MoM DEZ', datum: new Date(2025, 0, 16, 14, 30), konsens: '0,5%', vorherig: '0,7%', aktuell: '-', erklaerung: 'Zeigt Veränderungen im Konsumverhalten. Wichtig für BIP-Prognosen.' },
    { ereignis: 'Baugenehmigungen Vorl. DEZ', datum: new Date(2025, 0, 17, 14, 30), konsens: '1,6M', vorherig: '1,493M', aktuell: '-', erklaerung: 'Indikator für zukünftige Bauaktivitäten. Wichtig für Immobilien- und Baustoffsektor.' },
    { ereignis: 'Baubeginne DEZ', datum: new Date(2025, 0, 17, 14, 30), konsens: '1,5M', vorherig: '1,289M', aktuell: '-', erklaerung: 'Zeigt die Anzahl der begonnenen Neubauten.' },
    { ereignis: 'Verkäufe bestehender Häuser DEZ', datum: new Date(2025, 0, 24, 16, 0), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Wichtiger Indikator für den Immobilienmarkt.' },
    { ereignis: 'Auftragseingang langlebiger Güter MoM DEZ', datum: new Date(2025, 0, 28, 14, 30), konsens: '1,2%', vorherig: '-1,1%', aktuell: '-', erklaerung: 'Misst Bestellungen für langlebige Produkte. Wichtiger Indikator für Industrieproduktion.' },
    { ereignis: 'Fed Zinsentscheid', datum: new Date(2025, 0, 29, 20, 0), konsens: '-', vorherig: '4,5%', aktuell: '-', erklaerung: 'Zentrale geldpolitische Entscheidung der Federal Reserve.' },
    { ereignis: 'Fed Pressekonferenz', datum: new Date(2025, 0, 29, 20, 30), konsens: '-', vorherig: '-', aktuell: '-', erklaerung: 'Erläuterungen zur Zinsentscheidung und Ausblick.' },
    { ereignis: 'BIP-Wachstumsrate QoQ Vorl. Q4', datum: new Date(2025, 0, 30, 14, 30), konsens: '1,7%', vorherig: '3,1%', aktuell: '-', erklaerung: 'Erste Schätzung des Wirtschaftswachstums im vierten Quartal.' },
    { ereignis: 'Kern-PCE-Preisindex MoM DEZ', datum: new Date(2025, 0, 31, 14, 30), konsens: '0,3%', vorherig: '0,1%', aktuell: '-', erklaerung: 'Bevorzugtes Inflationsmaß der Fed.' },
    { ereignis: 'Persönliche Einkommen MoM DEZ', datum: new Date(2025, 0, 31, 14, 30), konsens: '0,1%', vorherig: '-', aktuell: '-', erklaerung: 'Misst die Veränderung der Haushaltseinkommen.' },
    { ereignis: 'Persönliche Ausgaben MoM DEZ', datum: new Date(2025, 0, 31, 14, 30), konsens: '0,5%', vorherig: '-', aktuell: '-', erklaerung: 'Zeigt Veränderungen im Konsumverhalten der Haushalte.' }
  ];

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    }
    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const getColorClass = (aktuell, konsens, ereignis) => {
    if (aktuell === '-' || konsens === '-') return '';
    const aktuelValue = parseFloat(aktuell.replace(',', '.').replace('%', ''));
    const konsensValue = parseFloat(konsens.replace(',', '.').replace('%', ''));
    
    // Check if the event is related to inflation or prices
    const isInflationOrPriceRelated = ereignis.toLowerCase().includes('inflation') || 
                                    ereignis.toLowerCase().includes('preis') ||
                                    ereignis.toLowerCase().includes('pce');
    
    if (isInflationOrPriceRelated) {
      // For inflation and price metrics, lower than expected is good (green), higher is bad (red)
      return aktuelValue < konsensValue ? 'green' : aktuelValue > konsensValue ? 'red' : '';
    } else {
      // For other metrics, higher than expected is good (green), lower is bad (red)
      return aktuelValue > konsensValue ? 'green' : aktuelValue < konsensValue ? 'red' : '';
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content calendar-modal">
        <h2>Wirtschaftsdaten-Veröffentlichungen</h2>
        
        <div className="calendar-table-container">
          <table className="calendar-table">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Ereignis</th>
                <th>Konsens</th>
                <th>Vorherig</th>
                <th>Aktuell</th>
              </tr>
            </thead>
            <tbody>
              {wirtschaftsdaten.map((data, index) => (
                <tr key={`economic-${index}`}>
                  <td>{format(data.datum, "dd.MM.yyyy HH:mm", { locale: de })}</td>
                  <td>
                    <span data-tooltip-id={`tooltip-${index}`}>
                      {data.ereignis}
                    </span>
                    <Tooltip id={`tooltip-${index}`} className="custom-tooltip">
                      <span>{data.erklaerung}</span>
                    </Tooltip>
                  </td>
                  <td>{data.konsens}</td>
                  <td>{data.vorherig}</td>
                  <td className={getColorClass(data.aktuell, data.konsens, data.ereignis)}>
                    {data.aktuell}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <button className="calendar-close-modal" onClick={() => setIsOpen(false)}>X</button>
      </div>
    </div>
  );
};

export default EarningsCalendar;
