// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Komponente RealtimeIndicator Styles */
.realtime-indicator,
.delayed-indicator {
  display: inline-flex;
  align-items: center;
  font-size: 0.7rem;
  font-weight: bold;
  margin-left: 0.5rem;
}

.indicator-box {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.realtime-indicator .indicator-box {
  background-color: rgba(57, 255, 20, 0.2);
  color: var(--success);
  border: 1px solid var(--success);
}

.delayed-indicator .indicator-box {
  background-color: rgba(128, 128, 128, 0.2);
  color: #a0a0a0;
  border: 1px solid #888;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.realtime-indicator .indicator-box::after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: lightingEffect 2s linear infinite;
}

@keyframes lightingEffect {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/realtimeindicator.css"],"names":[],"mappings":"AAAA,wCAAwC;AACxC;;EAEE,oBAAoB;EACpB,mBAAmB;EACnB,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,wCAAwC;EACxC,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;EACE,0CAA0C;EAC1C,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,YAAY;EACd;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,MAAM;EACN,WAAW;EACX,WAAW;EACX,YAAY;EACZ;;;;;GAKC;EACD,4CAA4C;AAC9C;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["/* Komponente RealtimeIndicator Styles */\n.realtime-indicator,\n.delayed-indicator {\n  display: inline-flex;\n  align-items: center;\n  font-size: 0.7rem;\n  font-weight: bold;\n  margin-left: 0.5rem;\n}\n\n.indicator-box {\n  padding: 0.25rem 0.5rem;\n  border-radius: 3px;\n  position: relative;\n  overflow: hidden;\n}\n\n.realtime-indicator .indicator-box {\n  background-color: rgba(57, 255, 20, 0.2);\n  color: var(--success);\n  border: 1px solid var(--success);\n}\n\n.delayed-indicator .indicator-box {\n  background-color: rgba(128, 128, 128, 0.2);\n  color: #a0a0a0;\n  border: 1px solid #888;\n}\n\n@keyframes pulse {\n  0% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.realtime-indicator .indicator-box::after {\n  content: \"\";\n  position: absolute;\n  top: 0;\n  left: -100%;\n  width: 100%;\n  height: 100%;\n  background: linear-gradient(\n    to right,\n    rgba(255, 255, 255, 0) 0%,\n    rgba(255, 255, 255, 0.3) 50%,\n    rgba(255, 255, 255, 0) 100%\n  );\n  animation: lightingEffect 2s linear infinite;\n}\n\n@keyframes lightingEffect {\n  0% {\n    left: -100%;\n  }\n  100% {\n    left: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
