import React, { useMemo, useState } from 'react';
import '../App.css';
import '../styles/chart.css';
import '../styles/datacard.css';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
  ReferenceLine, Area
} from 'recharts';
import RealtimeIndicator from './RealtimeIndicator';

const DataCard = ({ title, value, rating, chartData, category, explanation, chartConfig = {}, isRealtime = false, interpretationText, warningMessage, children }) => {
  const [isExplanationExpanded, setIsExplanationExpanded] = useState(false);

  const yAxisDomain = useMemo(() => {
    if (chartConfig.yAxisDomain) return chartConfig.yAxisDomain;
    if (!chartData || chartData.length === 0 || !chartConfig.dataKey) return [0, 'auto'];
    
    const values = chartData.map(item => item[chartConfig.dataKey]);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    
    if (title.toLowerCase().includes('arbeitslosigkeit')) {
      return [3, Math.max(3, maxValue + (maxValue - 3) * 0.1)];
    }
    
    const range = maxValue - minValue;
    const padding = range * 0.1;
    
    return [
      Math.max(0, minValue - padding),
      maxValue + padding
    ];
  }, [chartData, chartConfig.dataKey, chartConfig.yAxisDomain, title]);

  const formatYAxis = (tickItem) => {
    if (chartConfig.yAxisFormatter) {
      return chartConfig.yAxisFormatter(tickItem);
    }
    return Number(tickItem).toFixed(1);
  };

  return (
    <div className="data-card">
      <div className="data-card-header">
        <div>
          <h2>{title} <RealtimeIndicator isRealtime={isRealtime} /></h2>
          <div className="rating-container">
            <h2>{rating}</h2>
          </div>
        </div>
        {category && <span className="category-tag">{category}</span>}
      </div>
      <div className="data-card-content">
        {warningMessage && (
          <div className="data-card-warning">
            <p>⚠️ {warningMessage}</p>
          </div>
        )}
        {value && (
          <div className="data-card-value">
            {typeof value === 'string' ? <h3>{value}</h3> : value}
          </div>
        )}
        {explanation && (
          <div className="data-card-explanation-container">
            <button 
              className="explanation-toggle"
              onClick={() => setIsExplanationExpanded(!isExplanationExpanded)}
            >
              {isExplanationExpanded ? 'Erklärung ausblenden' : 'Erklärung anzeigen'}
            </button>
            {isExplanationExpanded && (
              <div className="data-card-explanation">
                {explanation}
              </div>
            )}
          </div>
        )}
        {interpretationText && <p className="data-card-interpretation" dangerouslySetInnerHTML={{ __html: interpretationText }}></p>}
        {children && <div className="data-card-custom-content">{children}</div>}
      </div>
      {chartData && chartData.length > 0 && (
        <div className="data-card-chart" style={{ width: '100%', height: '300px' }}>
          <ResponsiveContainer>
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey={chartConfig.xAxisDataKey} 
                tickFormatter={chartConfig.xAxisFormatter}
              />
              <YAxis domain={yAxisDomain} tickFormatter={formatYAxis} />
              <Tooltip 
                formatter={chartConfig.tooltipFormatter || ((value) => [value, chartConfig.dataKey])}
                labelFormatter={chartConfig.xAxisFormatter}
              />
              
              <ReferenceLine y={0} stroke="var(--text-color)" strokeWidth={1} />
              
              <Area
                type="monotone"
                dataKey={chartConfig.dataKey}
                baseLine={0}
                fill="var(--positive-area)"
                fillOpacity={0.3}
                stroke="none"
                isAnimationActive={false}
              />
              
              <Area
                type="monotone"
                dataKey={chartConfig.dataKey}
                baseLine={0}
                fill="var(--negative-area)"
                fillOpacity={0.3}
                stroke="none"
                isAnimationActive={false}
              />
              
              <Line 
                type="monotone" 
                dataKey={chartConfig.dataKey} 
                stroke="var(--highlight)" 
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default DataCard;
