// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Komponente UpgradeOverlay Styles */
.upgrade-overlay {
  position: relative;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.upgrade-options {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.upgrade-message {
  background-color: var(--frosted-bg);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid var(--frosted-border);
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 0 20px rgba(57, 255, 20, 0.1);
  color: var(--primary-text);
  text-align: center;
  max-width: 600px;
  width: 90%;
}

.upgrade-message h2 {
  color: var(--highlight);
  margin-bottom: 1rem;
  font-size: 1.5rem;
  text-shadow: 0 0 5px var(--highlight);
}

.upgrade-message p {
  margin-bottom: 1.5rem;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/upgradeoverlay.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;EACE,kBAAkB;EAClB,WAAW;EACX,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,mCAAmC;EACnC,2BAA2B;EAC3B,mCAAmC;EACnC,uCAAuC;EACvC,mBAAmB;EACnB,eAAe;EACf,2CAA2C;EAC3C,0BAA0B;EAC1B,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,qCAAqC;AACvC;;AAEA;EACE,qBAAqB;EACrB,eAAe;AACjB","sourcesContent":["/* Komponente UpgradeOverlay Styles */\n.upgrade-overlay {\n  position: relative;\n  width: 100%;\n  background-color: rgba(0, 0, 0, 0.7);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 1000;\n  padding: 1rem 0;\n  margin-bottom: 2rem;\n}\n\n.upgrade-options {\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  width: 100%;\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.upgrade-message {\n  background-color: var(--frosted-bg);\n  backdrop-filter: blur(10px);\n  -webkit-backdrop-filter: blur(10px);\n  border: 1px solid var(--frosted-border);\n  border-radius: 10px;\n  padding: 1.5rem;\n  box-shadow: 0 0 20px rgba(57, 255, 20, 0.1);\n  color: var(--primary-text);\n  text-align: center;\n  max-width: 600px;\n  width: 90%;\n}\n\n.upgrade-message h2 {\n  color: var(--highlight);\n  margin-bottom: 1rem;\n  font-size: 1.5rem;\n  text-shadow: 0 0 5px var(--highlight);\n}\n\n.upgrade-message p {\n  margin-bottom: 1.5rem;\n  font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
