import React, { useState } from 'react';
import { X } from 'lucide-react';
import '../styles/upgradeoverlay.css';

const UpgradeOverlay = ({ message }) => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="upgrade-overlay">
      <div className="upgrade-message">
        <button 
          className="modal-close" 
          onClick={() => setIsVisible(false)}
          aria-label="Close"
          style={{
            position: 'absolute',
            right: '15px',
            top: '5px',
            padding: '8px',
            cursor: 'pointer',
            background: 'none',
            border: 'none',
          }}
        >
          <X size={12} />
        </button>
        <h2>Upgrade erforderlich</h2>
        <p>{message || "Um auf alle Funktionen zugreifen zu können, benötigst Du ein aktives Abonnement."}</p>
        <a href="https://myablefy.com/s/projekt100x/projekt-100x-monatliche-zahlung-cb9109dc/payment" className="upgrade-button">
          Jetzt upgraden
        </a>
      </div>
    </div>
  );
};

export default UpgradeOverlay; 