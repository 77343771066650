import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase, getUserSubscription } from '../supabaseClient';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState(() => {
    // Initialize from localStorage if available
    const savedSub = localStorage.getItem('userSubscription');
    return savedSub ? JSON.parse(savedSub) : null;
  });
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 5;
  const RETRY_DELAY = 1000;

  const fetchSubscription = async (isRetry = false) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        setSubscription(null);
        localStorage.removeItem('userSubscription');
        setLoading(false);
        return;
      }

      // Only fetch if we don't have subscription data or if it's a forced refresh
      if (!subscription || isRetry) {
        const subData = await getUserSubscription();
        setSubscription(subData);
        localStorage.setItem('userSubscription', JSON.stringify(subData));
      }
      setLoading(false);
      setRetryCount(0);
    } catch (error) {
      console.error('Error fetching subscription:', error);
      
      if (isRetry && retryCount < MAX_RETRIES) {
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
          fetchSubscription(true);
        }, RETRY_DELAY * Math.pow(2, retryCount));
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // Initial fetch only if we don't have cached data
    if (!subscription) {
      fetchSubscription(false);
    } else {
      setLoading(false);
    }

    const authListener = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        // Only fetch if we don't have subscription data
        if (!subscription) {
          setLoading(true);
          fetchSubscription(false);
        }
      } else if (event === 'SIGNED_OUT') {
        setSubscription(null);
        localStorage.removeItem('userSubscription');
        setLoading(false);
      }
    });

    // Handle visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // Check if subscription data is stale (older than 5 minutes)
        const lastUpdate = localStorage.getItem('lastSubscriptionUpdate');
        const now = Date.now();
        if (!lastUpdate || now - parseInt(lastUpdate) > 5 * 60 * 1000) {
          fetchSubscription(true);
          localStorage.setItem('lastSubscriptionUpdate', now.toString());
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      authListener.data.subscription.unsubscribe();
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [subscription]);

  const refreshSubscription = () => {
    setLoading(true);
    setRetryCount(0);
    fetchSubscription(true);
  };

  return (
    <SubscriptionContext.Provider value={{ 
      subscription, 
      loading, 
      refreshSubscription,
      retryCount 
    }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
