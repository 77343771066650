import React, { useState, useEffect, useRef } from 'react';
import { supabase, sendMessage, deleteMessage, updateMessage, uploadImage, likeMessage, unlikeMessage, approveMessage } from '../supabaseClient';
import { useSubscription } from '../contexts/SubscriptionContext';
import ImageModal from '../components/ImageModal'; // You'll need to create this component
import { financialEmojis, emotionEmojis } from '../constants/customEmojis';
import UpgradeOverlay from '../components/UpgradeOverlay';
import '../styles/chat.css';
const ADMIN_EMAIL = '100x@maximilian.business';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [user, setUser] = useState(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [imageToUpload, setImageToUpload] = useState(null);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const messagesEndRef = useRef(null);
  const [likedMessages, setLikedMessages] = useState({});
  const [activeUsers, setActiveUsers] = useState(0);
  const [shouldScroll, setShouldScroll] = useState(true);
  const [messageLikes, setMessageLikes] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [replyingTo, setReplyingTo] = useState(null);

  const { subscription, loading } = useSubscription();

  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';
  const isAdmin = user && user.email === ADMIN_EMAIL;

  useEffect(() => {
    const checkUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
    };
    checkUser();

    // Subscribe to real-time updates
    const subscription = supabase
      .channel('public_chat')
      .on('postgres_changes', { 
        event: 'INSERT', 
        schema: 'public', 
        table: 'public_chat' 
      }, async payload => {
        // Fetch the complete message data including user_subscription
        const { data, error } = await supabase
          .from('public_chat')
          .select(`
            *,
            user_subscription:user_subscriptions!user_subscription_id (
              nickname
            )
          `)
          .eq('id', payload.new.id)
          .single();

        if (!error && data) {
          setMessages(currentMessages => [...currentMessages, data]);
        }
      })
      .subscribe();

    // Fetch initial messages
    fetchMessages();

    // Fetch active users
    const fetchActiveUsers = async () => {
      try {
        const response = await supabase.functions.invoke('calculate-active-users');
        if (response.error) throw response.error;
        setActiveUsers(response.data.activeUsers);
      } catch (error) {
        console.error('Error fetching active users:', error);
        setActiveUsers(69); // fallback number
      }
    };

    fetchActiveUsers();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, []);

  useEffect(() => {
    if (shouldScroll && messages.length > 0) {
      scrollToBottom();
    }
  }, [messages.length, shouldScroll]);

  useEffect(() => {
    if (messages.length > 0 && user) {
      const liked = {};
      messages.forEach(msg => {
        let likes = [];
        try {
          likes = JSON.parse(msg.like || '[]');
        } catch (e) {
          console.error('Error parsing likes:', e);
        }
        liked[msg.id] = Array.isArray(likes) && likes.includes(user.id);
      });
      setLikedMessages(liked);
    }
  }, [messages, user]);

  useEffect(() => {
    const calculateLikes = async () => {
      const likes = {};
      for (const msg of messages) {
        likes[msg.id] = await calculateDisplayLikes(msg);
      }
      setMessageLikes(likes);
    };
    calculateLikes();
  }, [messages]);

  const fetchMessages = async () => {
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    
    // Get current user
    const { data: { user } } = await supabase.auth.getUser();
    const isAdmin = user?.email === ADMIN_EMAIL;
    
    try {
      let query = supabase
        .from('public_chat')
        .select(`
          *,
          user_subscription:user_subscriptions(
            nickname,
            user_id
          )
        `);

      // Add date filter
      query = query.gte('date', sevenDaysAgo.toISOString());

      // Add visibility filter
      if (!isAdmin && user) {
        query = query.or(`visible.eq.true,name.eq."${user.email}"`);
      }

      // Add ordering
      query = query.order('date', { ascending: true });

      const { data, error } = await query;

      if (error) throw error;

      setMessages(data || []);
      
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
      }, 300);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (editingMessage) {
      await handleUpdate();
      return;
    }

    if ((!newMessage.trim() && !imageToUpload) || !user) return;

    try {
      let messageContent = newMessage;

      if (imageToUpload) {
        setIsUploading(true);
        const imageUrl = await uploadImage(imageToUpload);
        messageContent += `\n[Image](${imageUrl})`;
        setIsUploading(false);
        setImageToUpload(null);
        setImagePreview(null);
      }

      const messageData = {
        name: user.email,
        message: messageContent,
        reply_to: replyingTo?.id || null
      };
      await sendMessage(messageData);
      setNewMessage('');
      setReplyingTo(null);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleEdit = (msg) => {
    setEditingMessage(msg);
    setNewMessage(msg.message);
  };

  const handleUpdate = async () => {
    if (!editingMessage || !newMessage.trim()) return;

    try {
      await updateMessage(editingMessage.id, newMessage);
      setMessages(messages.map(msg => 
        msg.id === editingMessage.id ? {...msg, message: newMessage} : msg
      ));
      setEditingMessage(null);
      setNewMessage('');
    } catch (error) {
      console.error('Error updating message:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const { error } = await deleteMessage(id);
      if (error) throw error;
      // Only update UI after successful deletion
      setMessages(messages.filter(msg => msg.id !== id));
    } catch (error) {
      console.error('Error deleting message:', error);
      alert('Failed to delete message. Please try again.');
    }
  };

  const formatName = (email, msg) => {
    if (email === ADMIN_EMAIL) return 'Max';
    
    // Use the nickname from the joined user_subscription data
    if (msg.user_subscription?.nickname) {
      return msg.user_subscription.nickname;
    }
    
    return email.split('@')[0];
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}. ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleImageUpload = async (e) => {
    if (!user) {
      console.error('User not authenticated');
      return;
    }
    const file = e.target.files[0];
    if (!file) return;

    setImageToUpload(file);
    
    // Create a preview of the image
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const renderMessageContent = (content, replyToMessage) => {
    const parts = content.split(/(\[Image\]\(.*?\))/);
    const imageUrls = [];
    const textParts = [];

    parts.forEach((part) => {
      const imageMatch = part.match(/\[Image\]\((.*?)\)/);
      if (imageMatch) {
        imageUrls.push(imageMatch[1]);
      } else if (part.trim()) {
        textParts.push(part.trim());
      }
    });

    // URL regex pattern
    const urlPattern = /(https?:\/\/[^\s]+)/g;

    return (
      <>
        {replyToMessage && (
          <div 
            className="reply-reference"
            onClick={() => scrollToMessage(replyToMessage.id)}
            style={{ cursor: 'pointer' }}
          >
            ↱ Antwort an {formatName(replyToMessage.name, replyToMessage)}: {replyToMessage.message.substring(0, 50)}...
          </div>
        )}
        {imageUrls.map((url, index) => (
          <img
            key={index}
            src={url}
            alt="Uploaded"
            className="message-image"
            onClick={() => setEnlargedImage(url)}
          />
        ))}
        {textParts.length > 0 && (
          <div className="message-text">
            {textParts.map((text, index) => (
              <p key={index} style={{ whiteSpace: 'pre-wrap' }}>
                {text.split('\n').map((line, i) => (
                  <React.Fragment key={i}>
                    {line.split(urlPattern).map((part, j) => {
                      if (part.match(urlPattern)) {
                        return (
                          <a 
                            key={j}
                            href={part}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {part}
                          </a>
                        );
                      }
                      return part;
                    })}
                    {i < text.split('\n').length - 1 && <br />}
                  </React.Fragment>
                ))}
              </p>
            ))}
          </div>
        )}
      </>
    );
  };

  const handleLike = async (messageId) => {
    if (!user) return;

    try {
      setShouldScroll(false);
      let updatedMessage;
      if (likedMessages[messageId]) {
        updatedMessage = await unlikeMessage(messageId, user.id);
      } else {
        updatedMessage = await likeMessage(messageId, user.id);
      }

      if (updatedMessage && updatedMessage[0] && updatedMessage[0].like !== undefined) {
        setLikedMessages(prev => ({
          ...prev,
          [messageId]: !prev[messageId]
        }));

        setMessages(prevMessages => 
          prevMessages.map(msg => 
            msg.id === messageId ? { ...msg, like: updatedMessage[0].like } : msg
          )
        );
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error in handleLike:', error);
      alert('Failed to like/unlike the message. Please try again.');
    }
  };

  const calculateDisplayLikes = async (msg) => {
    try {
      const response = await supabase.functions.invoke('calculate-engagement', {
        body: msg
      });
      
      if (response.error) throw response.error;
      return response.data.totalLikes;
    } catch (e) {
      console.error('Error calculating likes:', e);
      return JSON.parse(msg.like || '[]').length; // Fallback to actual likes only
    }
  };

  const handleApprove = async (messageId) => {
    try {
      await approveMessage(messageId);
      // Update the local messages state
      setMessages(messages.map(msg => 
        msg.id === messageId ? { ...msg, visible: true } : msg
      ));
    } catch (error) {
      console.error('Error approving message:', error);
      alert('Failed to approve message. Please try again.');
    }
  };

  const handleEmojiClick = (emoji) => {
    setNewMessage(prev => prev + emoji.native);
    setShowEmojiPicker(false);
  };

  const handleReply = (msg) => {
    setReplyingTo(msg);
    setNewMessage(`@${formatName(msg.name, msg)} `);
  };

  const cancelReply = () => {
    setReplyingTo(null);
    setNewMessage('');
  };

  const scrollToMessage = (messageId) => {
    const messageElement = document.getElementById(`message-${messageId}`);
    if (messageElement) {
      messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      // Optional: Kurzes Highlighting der Nachricht
      messageElement.style.backgroundColor = 'rgba(57, 255, 20, 0.1)';
      setTimeout(() => {
        messageElement.style.backgroundColor = '';
      }, 1000);
    }
  };

  if (!user) {
    return <div>Loading...</div>;
  }

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Lade Mitgliedschafts-Status...</p>
      </div>
    );
  }

  return (
    <div className="chat-page">
      {!hasActiveSubscription && (
        <UpgradeOverlay/>
      )}
      {hasActiveSubscription && (
        <div className="chat-container">
          <div className="messages-container">
            {messages.map((msg) => {
              const isOwnMessage = msg.name === user.email;
              const replyToMessage = messages.find(m => m.id === msg.reply_to);
              return (
                <div 
                  key={msg.id} 
                  id={`message-${msg.id}`}
                  className={`message-wrapper ${isOwnMessage ? 'own-message' : 'other-message'}`}
                >
                  <div className={`message ${msg.name === ADMIN_EMAIL ? 'admin-message' : ''} ${!msg.visible && isAdmin ? 'pending-message' : ''}`}>
                    <div className="message-bubble">
                      <div className="message-header">
                        <span className="message-name">{formatName(msg.name, msg)}</span>
                        <span className="message-datetime">{formatDateTime(msg.date)}</span>
                        {!msg.visible && isAdmin && (
                          <span className="pending-badge">Pending Approval</span>
                        )}
                      </div>
                      <div className="message-content">
                        {renderMessageContent(msg.message, replyToMessage)}
                      </div>
                    </div>
                    <div className="message-actions">
                      <button 
                        onClick={() => handleLike(msg.id)}
                        className={`like-button ${likedMessages[msg.id] ? 'liked' : ''}`}
                      >
                        {likedMessages[msg.id] ? '❤️' : '🤍'} {messageLikes[msg.id] || 0}
                      </button>
                      {msg.name !== user.email && (
                        <button onClick={() => handleReply(msg)} style={{ borderRadius: '15px' }}>↩️</button>
                      )}
                      {isAdmin && !msg.visible && (
                        <button 
                          onClick={() => handleApprove(msg.id)}
                          className="approve-button"
                        >
                          Approve
                        </button>
                      )}
                      {isAdmin && (
                        <>
                          <button onClick={() => handleEdit(msg)} style={{ borderRadius: '15px' }}>✏️</button>
                          <button onClick={() => handleDelete(msg.id)}>Delete</button>
                        </>
                      )}
                      {!isAdmin && isOwnMessage && (
                        <button onClick={() => handleEdit(msg)} style={{ borderRadius: '15px' }}>✏️</button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            <div ref={messagesEndRef} />
          </div>
          <div className="chat-input-area">
            <div className="active-users-indicator">
              <span className="active-users-dot"></span>
              <span className="active-users-text">{activeUsers} aktive Nutzer in den letzten 24 Stunden</span>
            </div>
            {replyingTo && (
              <div className="reply-indicator">
                <span>Antwort an {formatName(replyingTo.name, replyingTo)}</span>
                <button onClick={cancelReply}>✕</button>
              </div>
            )}
            <form className="message-form" onSubmit={handleSubmit}>
              <textarea
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder={editingMessage ? "Edit your message..." : "Nachricht eingeben..."}
                className="message-input"
                rows="3"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleSubmit(e);
                  }
                }}
              />
              
              <button 
                type="button" 
                className="emoji-button"
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              >
                😊
              </button>
              
              {showEmojiPicker && (
                <div className="emoji-picker">
                  <div className="emoji-section">
                    <h4>Emotionen</h4>
                    <div className="emoji-grid">
                      {Object.values(emotionEmojis.emojis).map(emoji => (
                        <button
                          key={emoji.id}
                          type="button"
                          onClick={() => handleEmojiClick(emoji)}
                          title={emoji.name}
                        >
                          {emoji.native}
                        </button>
                      ))}
                    </div>
                  </div>
                  <div className="emoji-section">
                    <h4>Finanzen</h4>
                    <div className="emoji-grid">
                      {Object.values(financialEmojis.emojis).map(emoji => (
                        <button
                          key={emoji.id}
                          type="button"
                          onClick={() => handleEmojiClick(emoji)}
                          title={emoji.name}
                        >
                          {emoji.native}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              
              {imagePreview && (
                <div className="image-preview">
                  <img src={imagePreview} alt="Preview" />
                  <button type="button" onClick={() => {
                    setImageToUpload(null);
                    setImagePreview(null);
                  }}>Remove</button>
                </div>
              )}
              <label htmlFor="image-upload" className={`image-upload-label ${isUploading ? 'disabled' : ''}`}>
                📷
                <input
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                  disabled={isUploading}
                />
              </label>
              <button type="submit" className="send-button" disabled={isUploading}>
                <span className="send-icon">{editingMessage ? '✓' : '➤'}</span>
              </button>
              {editingMessage && (
                <button 
                  type="button" 
                  onClick={() => {
                    setEditingMessage(null);
                    setNewMessage(''); // Clear the message input
                  }} 
                  className="cancel-button"
                >
                  X
                </button>
              )}
            </form>
          </div>
          {enlargedImage && (
            <ImageModal imageUrl={enlargedImage} onClose={() => setEnlargedImage(null)} />
          )}
        </div>
      )}
    </div>
  );
};

export default Chat;
