import React, { useState} from 'react';
import { storeLead } from '../supabaseClient';
import '../styles/upgrademodal.css';

const UpgradeModal = ({ isOpen, onClose }) => {
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [email, setEmail] = useState('');
  const [receiveInfo, setReceiveInfo] = useState(false);
  const [showEmailCapture, setShowEmailCapture] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      alert('Bitte geben Sie eine E-Mail-Adresse ein.');
      return;
    }
    if (!receiveInfo) {
      alert('Bitte stimme den Bedingungen zu, um fortzufahren.');
      return;
    }

    setIsSubmitting(true);
    try {
      await storeLead(email, receiveInfo);
      // If successful, move to the subscription options
      setShowEmailCapture(false);
    } catch (error) {
      console.error('Error storing email:', error);
      alert(error.message);  // Use the specific error message
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleYearlyClick = () => {
    setShowPaymentOptions(true);
  };

  const handlePaymentOption = (option) => {
    if (option === 'card') {
      window.location.href = 'https://buy.stripe.com/14keYC7257Rk72EfZ1?locale=de';
    } else if (option === 'paypal') {
      window.location.href = 'https://myablefy.com/s/projekt100x/projekt-100x-monatliche-zahlung-cb9109dc/payment?locale=de';
    }
  };

  const handleMonthlySubscription = () => {
    window.location.href = 'https://myablefy.com/s/projekt100x/projekt-100x-monatliche-zahlung-cb9109dc/payment?locale=de';
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="upgrade-modal-overlay">
        <div className="upgrade-modal-content">
          <h2>Werde jetzt 100X Alpha Mitglied</h2>
          
          {showEmailCapture ? (
            <form onSubmit={handleEmailSubmit} className="email-capture-form">
              <h3>Erhalte sofort Zugriff auf die Mitgliedschafts-Preise - gib jetzt deine E-Mail ein!</h3>
              <input 
                type="email" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                placeholder="Deine E-Mail" 
                required 
              />
              <label>
                <input 
                  type="checkbox" 
                  checked={receiveInfo} 
                  onChange={(e) => setReceiveInfo(e.target.checked)} 
                  required
                />
                Ja, ich will die Preise einsehen und wertvolle Tipps und Strategien der 100X Alpha App erhalten!
              </label>
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Wird gesendet...' : 'Jetzt durchstarten!'}
              </button>
            </form>
          ) : (
            <>
              <div className="upgrade-benefits">
                <h3>Als Mitglied erhältst du Zugang zu:</h3>
                <ul>
                  <li>100X Aktien-Depot mit Performance-Daten</li>
                  <li>Trade-Alarm per SMS für wichtige Marktchancen</li>
                  <li>10+ Echtzeit-Marktindikatoren</li>
                  <li>Live-Chat Community mit Maximilian und anderen Mitgliedern</li>
                  <li>Mastermind Lerninhalte (Aktienanalysen, Optionsstrategien, etc.)</li>
                </ul>
                <div className="money-back-guarantee">
                  <h4>Geld-zurück-Garantie</h4>
                  <p>Wenn du nach einem Jahr bei einem Mindestinvestment von 10.000 EUR und Befolgen unserer Niedrig-Risiko-Optionsstrategien deine Mitgliedsgebühr nicht wieder reingeholt hast, erstatten wir dir den vollen Betrag zurück!</p>
                </div>
              </div>
              <div className="upgrade-options">
                <div className="upgrade-option">
                  <div className="upgrade-option-content">
                    <h3 className="text-center">Jahresplan</h3>
                    <p>Ein Jahr voller Wachstum und Lernen</p>
                    <p>Einmalige Zahlung für das gesamte Jahr</p>
                    <div className="price-container">
                      <span className="original-price">340€/Jahr</span>
                      <span className="discounted-price">240€/Jahr</span>
                    </div>
                  </div>
                  <div className="upgrade-option-footer">
                    <button onClick={handleYearlyClick} className="upgrade-button">
                      Jetzt Mitglied werden
                    </button>
                  </div>
                </div>
                <div className="upgrade-option">
                  <div className="upgrade-option-content">
                    <h3 className="text-center">Monatsplan</h3>
                    <p>Monatliche Zahlung für 12 Monate</p>
                    <div className="price-container">
                      <span className="original-price">34€/Monat</span>
                      <span className="discounted-price">24€/Monat</span>
                    </div>
                  </div>
                  <div className="upgrade-option-footer">
                    <button onClick={handleMonthlySubscription} className="upgrade-button">
                      Jetzt Mitglied werden
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          
          <button className="upgrade-modal-close" onClick={onClose}>&times;</button>
        </div>
      </div>
      
      {showPaymentOptions && (
        <div className="payment-options-overlay">
          <div className="payment-options-modal">
            <h3>Wähle deine Zahlungsmethode</h3>
            <button onClick={() => handlePaymentOption('card')}>Mit Karte zahlen</button>
            <button onClick={() => handlePaymentOption('paypal')}>Mit PayPal zahlen</button>
            <button className="payment-options-close" onClick={() => setShowPaymentOptions(false)}>&times;</button>
          </div>
        </div>
      )}
    </>
  );
};

export default UpgradeModal;
