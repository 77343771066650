import React from 'react';
import { Link } from 'react-router-dom';
import { useSubscription } from '../contexts/SubscriptionContext';
import '../styles/videolibrary.css';
import UpgradeOverlay from '../components/UpgradeOverlay';

const Mastermind = () => {
  const { subscription } = useSubscription();
  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';
  
  // Update countdown calculation to target Oct 27, 23:59 German time
  const getCountdownDate = () => {
    const targetDate = new Date('2024-11-06T23:59:00+01:00'); // German timezone (CET - winter time)
    return targetDate;
  };

  const [timeLeft, setTimeLeft] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = getCountdownDate().getTime() - now;
      
      // If countdown is finished
      if (distance < 0) {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        });
        clearInterval(timer);
        return;
      }

      setTimeLeft({
        days: Math.floor(distance / (1000 * 60 * 60 * 24)),
        hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((distance % (1000 * 60)) / 1000)
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="mastermind-page">
      <h1>100X Mastermind</h1>
      
      <div className="broker-recommendations">
        {/* Interactive Brokers Recommendation */}
        <div className="broker-recommendation">
          <h2>Bester Broker für Optionsstrategien</h2>
          <div className="broker-content">
            <div className="broker-info">
              <p>Für die optimale Umsetzung unserer Optionsstrategien empfehlen wir IBKR (Interactive Brokers):</p>
              <ul className="broker-features">
                <li>Niedrige Handelsgebühren</li>
                <li>Umfangreiche Optionshandels-Möglichkeiten</li>
                <li>Zuverlässigste Trading-Plattform</li>
                <li>Bis zu $1.000 Anmeldeprämie in Aktien ausgezahlt</li>
              </ul>
              <a href="https://ibkr.com/referral/maximilian328" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 className="broker-cta">
                Jetzt bei IBKR registrieren
              </a>
            </div>
          </div>
        </div>

        {/* XTB Recommendation */}
        <div className="broker-recommendation">
          <h2>Bester Allround-Broker</h2>
          <div className="broker-content xtb">
            <img 
              src="/images/de-kv-hp-desktop-zi-wymw-2024-1140x320.png" 
              alt="XTB Broker" 
              className="broker-banner"
            />
            <div className="broker-info">
              <p>Für Aktien- und CFD-Trading empfehlen wir XTB:</p>
              <div className="broker-features-grid">
                <div className="feature-category">
                  <h3>Aktien und ETFs</h3>
                  <p>Über 3.000 Wertpapiere aus Europa und den USA</p>
                </div>
                <div className="feature-category">
                  <h3>Forex, Rohstoffe, Indizes</h3>
                  <p>Über 2.300 gehebelte CFD Instrumente</p>
                </div>
                <div className="feature-category">
                  <h3>Kryptowährungen</h3>
                  <p>Über 40 CFD auf BITCOIN BTC, ETH, etc.</p>
                </div>
                <div className="feature-category">
                  <h3>Sparpläne</h3>
                  <p>Entdecken Sie die smarte Art des Investierens auf Basis von ETFs - bester Sparplan Broker laut Handelsblatt</p>
                </div>
                <div className="feature-category highlight">
                  <h3>Guthabenzinsen</h3>
                  <p>Verdienen Sie Zinsen, während Sie auf die perfekte Investmentmöglichkeit warten - 4,2% für die ersten 3 Monate</p>
                </div>
              </div>
              <a href="https://geolink.xtb.com/D0Nk2" 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 className="broker-cta">
                Jetzt bei XTB registrieren
              </a>
            </div>
          </div>
        </div>
      </div>

      <p>Tauche ein in die wachsende Video-Bibliothek. Profitiere von praxisnahen Tutorials und Wissen für fortgeschrittene Kapitalmarktstrategien.</p>

      {!hasActiveSubscription && <UpgradeOverlay />}

      <div className={`course-library ${!hasActiveSubscription ? 'blurred' : ''}`}>
        {/* Featured Course */}
        <section className="featured-course">
          <div className="featured-course-card">
            <div className="course-info">
              <h3>Wie du die 100X App verwendest</h3>
              <p>Lerne die Grundlagen unserer 100X App kennen, um dein Investitionserlebnis zu optimieren.</p>
              <Link to="/course/app-introduction" className="enroll-button">
                Jetzt starten
              </Link>
            </div>
          </div>
        </section>

        {/* Covered Calls Course */}
        <section className="featured-course">
          <div className="featured-course-card">
            <div className="course-info">
              <div className="course-tags">
                <span className="tag">Anfänger</span>
                <span className="tag">Niedriges Risiko</span>
              </div>
              <h3>Covered Calls Strategie</h3>
              <p>Generiere zusätzliches Einkommen aus deinen bestehenden Aktienbeständen. Lerne, wie du mit der Covered Calls Strategie regelmäßige Prämieneinnahmen erzielst und dein Portfolio optimierst.</p>
              <Link to="/covered-calls" className="enroll-button">
                Jetzt starten
              </Link>
            </div>
          </div>
        </section>

        {/* Short Selling Course */}
        <section className="featured-course">
          <div className="featured-course-card">
            <div className="course-info">
              <div className="course-tags">
                <span className="tag">Fortgeschritten</span>
                <span className="tag">Hohes Risiko</span>
              </div>
              <h3>Crashkurs: Leerverkaufen (Short gehen)</h3>
              <p>Lerne die Grundlagen und fortgeschrittenen Techniken des Leerverkaufens. Verstehe die Risiken und Chancen dieser Handelsstrategie und wie du sie effektiv in dein Trading integrieren kannst.</p>
              <Link to="/short-selling" className="enroll-button">
                Jetzt starten
              </Link>
            </div>
          </div>
        </section>

        {/* Coming Soon Teaser */}
        <section className="coming-soon-teaser">
          <div className="teaser-card">
            <h3>Weitere Kurse in Kürze verfügbar</h3>
            <p>Bleib gespannt auf neue Strategien und Anleitungen. Wir erweitern unsere Bibliothek kontinuierlich mit wertvollen Inhalten für dich.</p>
            <div className="coming-soon-badge">Demnächst verfügbar</div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Mastermind;
