/* global Stream */  // Add this at the top of your file

import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useSubscription } from '../contexts/SubscriptionContext';
import '../styles/videolibrary.css';

import { supabase, getVideoProgress, updateVideoProgress } from '../supabaseClient';

const Course = () => {
  const BASE_VIDEO_URL = "https://customer-ixkpt5q6n9uwmb0u.cloudflarestream.com/323c848e74375e2cb0c4307251a036af/iframe?poster=https%3A%2F%2Fcustomer-ixkpt5q6n9uwmb0u.cloudflarestream.com%2F323c848e74375e2cb0c4307251a036af%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600";
  const { subscription } = useSubscription();
  const hasActiveSubscription = subscription && subscription.subscription_status === 'active';
  const COURSE_ID = 1; // Hardcoded course ID
  const playerRef = useRef(null);
  const [lastPosition, setLastPosition] = useState(0);
  const [progress, setProgress] = useState(0); // New state for progress percentage

  useEffect(() => {
    if (!hasActiveSubscription) return;

    // Load the Cloudflare Stream SDK
    const script = document.createElement('script');
    script.src = 'https://embed.cloudflarestream.com/embed/sdk.latest.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = async () => {
      // Initialize player after SDK loads
      const player = Stream(document.getElementById('stream-player'));
      playerRef.current = player;

      // Load saved progress
      try {
        const savedProgress = await getVideoProgress(COURSE_ID);
        if (savedProgress?.last_position) {
          player.currentTime = savedProgress.last_position;
          setLastPosition(savedProgress.last_position);
          setProgress(savedProgress.progress || 0); // Initialize progress state
        }
      } catch (error) {
        console.error('Error loading progress:', error);
      }

      // Add event listeners
      player.addEventListener('timeupdate', handleTimeUpdate);
      player.addEventListener('ended', handleVideoEnd);
    };

    return () => {
      // Cleanup
      if (playerRef.current) {
        playerRef.current.removeEventListener('timeupdate', handleTimeUpdate);
        playerRef.current.removeEventListener('ended', handleVideoEnd);
      }
      document.body.removeChild(script);
    };
  }, [hasActiveSubscription]); // Add hasActiveSubscription as dependency

  const handleTimeUpdate = async () => {
    const player = playerRef.current;
    if (!player) return;

    const currentTime = player.currentTime;
    const duration = player.duration;
    const calculatedProgress = Math.round((currentTime / duration) * 100);

    // Only update if position has changed significantly (e.g., every 5 seconds)
    if (Math.abs(currentTime - lastPosition) >= 5) {
      try {
        await updateVideoProgress(COURSE_ID, calculatedProgress, currentTime);
        setLastPosition(currentTime);
        setProgress(calculatedProgress); // Update progress state
      } catch (error) {
        console.error('Error updating progress:', error);
      }
    }
  };

  const handleVideoEnd = async () => {
    try {
      await updateVideoProgress(COURSE_ID, 100, playerRef.current.duration);
      setProgress(100); // Update progress state to 100% on video end
    } catch (error) {
      console.error('Error updating final progress:', error);
    }
  };

  if (!hasActiveSubscription) {
    return <Navigate to="/mastermind" />;
  }

  return (
    <div className="course-page">
      <div className="video-container">
        <div style={{ position: 'relative', paddingTop: '56.25%' }}>
          <iframe
            src={BASE_VIDEO_URL}
            id="stream-player"
            style={{
              border: 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%'
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
            allowFullScreen={true}
            loading="lazy"
          />
        </div>
      </div>

      <div className="course-content">
        <h1>Willkommen bei 100X Alpha</h1>

        {/* Progress Bar Section */}
        <div className="course-progress">
          <h2>Ihr Fortschritt</h2>
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
          <span>{progress}% abgeschlossen</span>
        </div>

        <div className="course-chapters">
          <div className="chapter-list">
            {/* Add your chapter list here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
