// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AccountPage Styles */

.nickname-section {
  margin: 10px 0;
}

.nickname-edit {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.nickname-edit input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.nickname-display {
  display: flex;
  gap: 10px;
  align-items: center;
}

.account-actions a {
  display: block;
}

.account-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
  max-width: 300px; /* Adjust this value as needed */
}

.logout-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-weight: bold;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #c0392b;
}
.green {
  color: green;
}
.red {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/styles/accountpage.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,WAAW;EACX,gBAAgB,EAAE,gCAAgC;AACpD;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd;AACA;EACE,UAAU;AACZ","sourcesContent":["/* AccountPage Styles */\n\n.nickname-section {\n  margin: 10px 0;\n}\n\n.nickname-edit {\n  display: flex;\n  gap: 10px;\n  margin-top: 5px;\n}\n\n.nickname-edit input {\n  padding: 5px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.nickname-display {\n  display: flex;\n  gap: 10px;\n  align-items: center;\n}\n\n.account-actions a {\n  display: block;\n}\n\n.account-actions {\n  display: flex;\n  flex-direction: column;\n  gap: 1rem;\n  margin-top: 2rem;\n  width: 100%;\n  max-width: 300px; /* Adjust this value as needed */\n}\n\n.logout-button {\n  margin-top: 20px;\n  padding: 10px 20px;\n  font-weight: bold;\n  background-color: #e74c3c;\n  color: white;\n  border: none;\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.logout-button:hover {\n  background-color: #c0392b;\n}\n.green {\n  color: green;\n}\n.red {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
