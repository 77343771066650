// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Komponente Summary Styles */
.summary-text-container {
  max-height: 400px;
  overflow-y: auto;
  margin-top: 1rem;
}

.summary-text {
  font-size: 1rem;
  line-height: 1.6;
  white-space: pre-wrap;
  font-family: "Consolas", "Courier New", monospace;
  background-color: var(--secondary-bg);
  border: 1px solid var(--primary-text);
  border-radius: 8px;
  padding: 1rem;
  color: var(--primary-text);
  overflow-x: auto;
}

.summary-text p {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.summary-text h4 {
  font-size: 1.1rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--highlight);
}

.summary-text ul {
  font-size: 1rem;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.summary-text li {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/styles/summary.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;EACE,iBAAiB;EACjB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,iDAAiD;EACjD,qCAAqC;EACrC,qCAAqC;EACrC,kBAAkB;EAClB,aAAa;EACb,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB","sourcesContent":["/* Komponente Summary Styles */\n.summary-text-container {\n  max-height: 400px;\n  overflow-y: auto;\n  margin-top: 1rem;\n}\n\n.summary-text {\n  font-size: 1rem;\n  line-height: 1.6;\n  white-space: pre-wrap;\n  font-family: \"Consolas\", \"Courier New\", monospace;\n  background-color: var(--secondary-bg);\n  border: 1px solid var(--primary-text);\n  border-radius: 8px;\n  padding: 1rem;\n  color: var(--primary-text);\n  overflow-x: auto;\n}\n\n.summary-text p {\n  font-size: 1rem;\n  margin-bottom: 1rem;\n}\n\n.summary-text h4 {\n  font-size: 1.1rem;\n  margin-top: 1.5rem;\n  margin-bottom: 0.5rem;\n  color: var(--highlight);\n}\n\n.summary-text ul {\n  font-size: 1rem;\n  margin-bottom: 1rem;\n  padding-left: 1.5rem;\n}\n\n.summary-text li {\n  font-size: 1rem;\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
