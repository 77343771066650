import React from 'react';
import '../styles/changelogmodal.css';

const ChangelogModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="changelog-modal-overlay" onClick={onClose}>
      <div className="changelog-modal" onClick={(e) => e.stopPropagation()}>
        <h2>Neue Funktionen</h2>
        <div className="changelog-content">
          {/* Add new version */}
          <h3>Version 0.1.6 - 29.11.2024</h3>
          <ul>
            <li>Fehlerbehebungen</li>
            <li>Neue Chat Features:
              <ul>
                <li>Emoji Reaktionen</li>
                <li>Reply Funktion</li>
              </ul>
            </li>
          </ul>
          <h3>Version 0.1.5 - 23.11.2024</h3>
          <ul>
            <li>Fehlerbehebungen</li>
            <li>Neue Funktion: Community Wunschliste</li>
          </ul>
          <h3>Version 0.1.4 - 10.11.2024</h3>
          <ul>
            <li>Fehlerbehebungen</li>
            <li>Chat-Nickname ändern</li>
          </ul>
          <h3>Version 0.1.3 - 30.10.2023</h3>
          <ul>
            <li>Fehlerbehebungen</li>
            <li>Neue Funktion: Insider-Analyse</li>
          </ul>
          <h3>Version 0.1.2 - 18.10.2023</h3>
          <ul>
            <li>Neuer Indikator: Zins-Injektion</li>
            <li>Neue Sektion: Trades</li>
          </ul>
          <h3>Version 0.1.1 - 12.10.2023</h3>
          <ul>
            <li>Fehlerbehebungen</li>
            <li>Neuer Indikator: Globale KGV-Verhältnisse</li>
            <li>Verbesserte Chat-Erfahrung</li>
          </ul>
        </div>
        <button onClick={onClose}>Schließen</button>
      </div>
    </div>
  );
};

export default ChangelogModal;
