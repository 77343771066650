import React, { useState, useEffect } from 'react';
import DataCard from './DataCard';
import { currentUnemploymentRating, getPillClassByText } from './MarketScoreComponent';
import { fetchHistoricalUnemploymentRate, fetchLatestUnemploymentRate } from '../services/marketDataService';

const UnemploymentComponent = () => {
  const [unemploymentData, setUnemploymentData] = useState(null);
  const [historicalData, setHistoricalData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [localUnemploymentRating, setLocalUnemploymentRating] = useState('Wird geladen...');

  useEffect(() => {
    const loadUnemploymentData = async () => {
      try {
        setLoading(true);
        const latestData = await fetchLatestUnemploymentRate();
        const historicalUnemployment = await fetchHistoricalUnemploymentRate(); 
        setUnemploymentData(latestData);
        setHistoricalData(historicalUnemployment.map(d => ({
          date: d.date.split('T')[0],
          value: parseFloat(d.unemployment_rate)
        })));
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadUnemploymentData();
  }, []);

  useEffect(() => {
    if (currentUnemploymentRating) {
      setLocalUnemploymentRating(currentUnemploymentRating);
    }

    const intervalId = setInterval(() => {
      if (currentUnemploymentRating && currentUnemploymentRating !== localUnemploymentRating) {
        setLocalUnemploymentRating(currentUnemploymentRating);
      }
    }, 100);

    return () => clearInterval(intervalId);
  }, [localUnemploymentRating]);

  if (loading) {
    return <div className="loading">Arbeitslosendaten werden geladen...</div>;
  }

  if (error) {
    return <div className="error">Fehler beim Abrufen der Arbeitslosendaten: {error}</div>;
  }

  if (!unemploymentData) {
    return <div className="error">Keine Arbeitslosendaten verfügbar.</div>;
  }

  const currentRate = parseFloat(unemploymentData.unemployment_rate);

  const getUnemploymentState = (current, previous) => {
    const difference = current - previous;
    if (Math.abs(difference) < 0.1) return 'stabil';
    return difference > 0 ? 'steigend' : 'fallend';
  };

  const state = getUnemploymentState( 
    currentRate,
    historicalData[historicalData.length - 2].value
  );

  // Calculate Sahm Rule
  const calculateSahmRule = () => {
    if (historicalData.length < 15) {
      return { triggered: false, level: null, debug: 'Insufficient data' };
    }

    const unemploymentRates = historicalData.map((d) => d.value);
    let sahmRuleValues = [];
    let debugInfo = [];

    for (let t = 14; t < unemploymentRates.length; t++) {
      const currentThreeMonthAvg =
        (unemploymentRates[t] +
          unemploymentRates[t - 1] +
          unemploymentRates[t - 2]) /
        3;

      const prior12MonthsRates = unemploymentRates.slice(t - 12, t);
      const minUnemploymentRate = Math.min(...prior12MonthsRates);

      const sahmValue = currentThreeMonthAvg - minUnemploymentRate;
      sahmRuleValues.push(sahmValue);

      debugInfo.push({
        month: historicalData[t].date,
        currentThreeMonthAvg,
        minUnemploymentRate,
        sahmValue,
        prior12MonthsRates,
      });
    }

    const latestSahmValue = sahmRuleValues[sahmRuleValues.length - 1];
    const sahmRuleTriggered = latestSahmValue >= 0.5;

    return {
      triggered: sahmRuleTriggered,
      level: latestSahmValue,
      debug: debugInfo,
    };
  };

  const sahmRuleResult = calculateSahmRule();

  const chartConfig = {
    xAxisDataKey: 'date',
    dataKey: 'value',
    tooltipFormatter: (value) => `${value.toFixed(1)}%`,
    yAxisFormatter: (value) => `${value}%`,
    referenceLine: {
      y: 0.5,
      stroke: 'red',
      strokeDasharray: '3 3',
      label: {
        value: 'Sahm-Regel Schwelle',
        position: 'insideTopLeft',
        fill: 'red',
      },
    },
  };

  const interpretationText = `Die aktuelle Arbeitslosenquote beträgt <strong>${currentRate.toFixed(
    1
  )}%</strong> und ist ${state}. ${
    currentRate < 4
      ? 'Dies gilt als niedrige Arbeitslosigkeit und deutet auf einen starken Arbeitsmarkt hin.'
      : currentRate >= 4 && currentRate < 6
      ? 'Dies liegt noch im normalen Bereich für die Arbeitslosigkeit, könnte sich aber schnell ändern.'
      : 'Dies gilt als hohe Arbeitslosigkeit und deutet auf mögliche wirtschaftliche Herausforderungen hin.'
  } Der aktuelle Sahm-Regel-Indikator beträgt <strong>${sahmRuleResult.level.toFixed(
    2
  )}</strong>. ${
    sahmRuleResult.triggered
      ? 'Die Sahm-Regel wurde ausgelöst (≥ 0,5), was auf eine mögliche Rezession hindeutet.'
      : 'Die Sahm-Regel wurde nicht ausgelöst (< 0,5), was auf relative wirtschaftliche Stabilität hindeutet.'
  }`;

  const expandedExplanation = `
Die Arbeitslosenquote misst den Prozentsatz der Erwerbsbevölkerung, der arbeitslos ist und aktiv nach Arbeit sucht. 

1. Niedrige Arbeitslosigkeit (< 4%):
   • Starker Arbeitsmarkt, oft bullisch für die Wirtschaft
   • Konträre Sicht: Möglicher Arbeitskräftemangel, Lohninflationsdruck
   • Strategie: Fokus auf Unternehmen mit starker Preissetzungsmacht

2. Moderate Arbeitslosigkeit (4-6%):
   • Gesunder Arbeitsmarkt, Gleichgewicht zwischen Angebot und Nachfrage
   • Konträre Sicht: Potenzial für weitere Verbesserung
   • Strategie: Ausgewogene Investitionen in verschiedene Sektoren

3. Hohe Arbeitslosigkeit (> 6%):
   • Schwacher Arbeitsmarkt, oft bearish für die Wirtschaft
   • Konträre Sicht: Möglichkeit für geldpolitische Stimuli
   • Strategie: Defensive Sektoren, Qualitätsunternehmen mit stabilen Cashflows

4. Sahm-Regel:
   Die Sahm-Regel ist ein Indikator für den Beginn einer Rezession, basierend auf Veränderungen in der Arbeitslosenquote. Sie wird ausgelöst, wenn der 3-Monats-Durchschnitt der Arbeitslosenquote um 0,5 Prozentpunkte oder mehr über dem Tiefstwert der letzten 12 Monate liegt.

   • Ausgelöst: Starkes Rezessionssignal
   • Nicht ausgelöst: Relative wirtschaftliche Stabilität
   • Strategie: Bei Auslösung Vorbereitung auf mögliche Rezession, Anpassung des Portfolios

5. Trendwenden:
   • Beginn des Anstiegs: Möglicher Indikator für wirtschaftliche Abschwächung
   • Beginn des Rückgangs: Möglicher Indikator für wirtschaftliche Erholung
   • Strategie: Frühzeitige Positionierung für den nächsten Wirtschaftszyklus

Wichtiger Hinweis:
Die Arbeitslosenquote ist ein nachlaufender Indikator. Sie sollte in Kombination mit anderen wirtschaftlichen Indikatoren betrachtet werden, um ein vollständiges Bild der wirtschaftlichen Lage zu erhalten.
`;

  const warningMessage = `Achtung: Die Genauigkeit der aktuellen Arbeitslosendaten ist aufgrund von Datenrevisionen und einem ungewöhnlich hohen Wachstum der Regierungsjobs möglicherweise beeinträchtigt.`;

  return (
    <DataCard
      title="US-Arbeitslosigkeit"
      rating={
        <span className={`pill ${getPillClassByText(localUnemploymentRating)}`}>
          {localUnemploymentRating}
        </span>
      }
      value={`${currentRate.toFixed(1)}% (${state})`}
      timestamp={unemploymentData.date}
      chartData={historicalData}
      category="Wirtschaft"
      explanation={<pre className="expanded-explanation">{expandedExplanation}</pre>}
      chartConfig={chartConfig}
      isRealtime={false}
      interpretationText={interpretationText}
      warningMessage={warningMessage}
    />
  );
};

export default UnemploymentComponent;
